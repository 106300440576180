import React, { useEffect, useState } from 'react';  
// CSS  
import styles from './SearchBar.module.css';  
// Components  
import ResultItem from './ResultItem/ResultItem';  
  
type SearchBarProps = {  
  data?: string[];  
  preset?: string;  
  type: string;  
  value?: string;  
  onChange?: (value: string) => void;  
};  
  
const SearchBar = ({ data, preset, type, value, onChange }: SearchBarProps) => {  
  const [searchTerm, setSearchTerm] = useState(value || "");  
  const [results, setResults] = useState<string[]>([]);  
  
  useEffect(() => {  
    setSearchTerm(preset || "");  
  }, [preset]);  
  
  useEffect(() => {  
    if (data) {  
      const filteredResourceResults = data.filter((item: string) => {  
        const searchLower = searchTerm.toLowerCase();  
        const titleMatch = item.toLowerCase().includes(searchLower);  
        return titleMatch;  
      });  
      setResults(filteredResourceResults);  
    }  
  }, [searchTerm, data]);  
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const newValue = e.target.value;  
    setSearchTerm(newValue);  
    if (onChange) {  
      onChange(newValue);  
    }  
  };  
  
  return (  
    <div className={`${styles.container} search-bar`}>  
      <div className={styles.inputContainer}>  
        <span className={styles.icon}>  
          <img src='/img/search-icon.svg' alt='Search Icon' />  
        </span>  
        <input  
          value={searchTerm}  
          className={styles.input}  
          placeholder='Search...'  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className={styles.resultsContainer}>  
        {searchTerm && results.length > 0 && (  
          <div className={styles.results}>  
            {results.map((result, index) => (  
              <ResultItem key={index} item={result} type={type} />  
            ))}  
          </div>  
        )}  
      </div>  
    </div>  
  );  
};  
  
export default SearchBar;  