import React, { useEffect, useState } from "react";  
import styles from "./PagedCardCollection.module.css";  
import { AreaDisplayInfo } from "../../../../Types/AreaDisplayInfo/AreaDisplayInfo";  
import CardCollection from "../CardCollection";  
import Paging from "../../Paging/Paging";  
import Skeleton from "../../Skeleton/Skeleton";  
import { PagedRequest } from "../../../../Types/Paging/PagedRequest";  
import { useAppDispatch, useAppSelector } from "../../../../Redux/Store/Store";  
import { toast } from "react-toastify";  
import { PagedResponse } from "../../../../Types/Paging/PagedResponse";  
  
type PagedCardCollectionProps = {  
  archived: boolean;  
  template: boolean;  
  fetchData: (request: PagedRequest) => Promise<PagedResponse<AreaDisplayInfo>>;  
  searchTerm: string;  
  toggleArchiveHandler?: (id: string) => void;
};  
  
const PagedCardCollection = ({  
  archived,  
  fetchData,  
  template,  
  searchTerm,  
  toggleArchiveHandler
}: PagedCardCollectionProps) => {  
  const dispatch = useAppDispatch();  
  const pageSize = 3;  
  const [activePage, setActivePage] = useState<number>(1);  
  const [pageCount, setPageCount] = useState<number>(1);  
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);  
  
  const [displayInfos, setDisplayInfos] = useState<AreaDisplayInfo[]>([]);  
  
  const getData = async () => {  
    try {  
      const data = await fetchData({  
        pageNumber: activePage,  
        pageSize: pageSize,  
        searchTerm: debouncedSearchTerm,  
      } as PagedRequest);  
      setDisplayInfos(data.items);  
      setPageCount(Math.ceil(data.totalCount / pageSize));  
      console.log(`Page count: ${Math.ceil(data.totalCount / pageSize)}`);  
    } catch (error) {  
      console.error(error);  
      toast.error(`There was a problem retrieving data. Error: ${error}`);  
    }  
  };  
  
  useEffect(() => {  
    const handler = setTimeout(() => {  
      setDebouncedSearchTerm(searchTerm);  
    }, 500); // Adjust the delay as needed (500ms in this case)  
  
    return () => {  
      clearTimeout(handler);  
    };  
  }, [searchTerm]);  
  
  useEffect(() => {  
    setActivePage(1);  
  }, [debouncedSearchTerm]);  
  
  useEffect(() => {  
    getData();  
  }, [activePage, debouncedSearchTerm]);  
  
  const pageChanged = (page: number) => {  
    setActivePage(page);  
  };  
  
  const toggleArchived = async (id: string) => {

    setDisplayInfos((prevDisplayInfos) => prevDisplayInfos.filter(info => info.id !== id));

    if (toggleArchiveHandler) {
      await toggleArchiveHandler(id);
    }
  };
  
  if (displayInfos) {  
    return (  
      <div className={styles.outerContainer}>  
        {displayInfos.length === 0 ? (  
          <p>There are no items to display.</p>  
        ) : (  
          <>  
            <CardCollection toggleArchiveHandler={toggleArchived} archived={archived} areas={displayInfos} template={template} />  
            <Paging activePage={activePage} pageCount={pageCount} pageChanged={pageChanged} />  
          </>  
        )}  
      </div>  
    );  
  } else {  
    return (  
      <>  
        <Skeleton loading={true}>  
          <div></div>  
        </Skeleton>  
        <Skeleton loading={true}>  
          <div></div>  
        </Skeleton>  
        <Skeleton loading={true}>  
          <div></div>  
        </Skeleton>  
      </>  
    );  
  }  
};  
  
export default PagedCardCollection;  