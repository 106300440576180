import { useAuth0 } from '@auth0/auth0-react'
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './Tour.css';
//Components
import Navbar from './Components/Navbar/Navbar';
import AuthLogin from './authLogin';
import { useTour } from '@reactour/tour'
import SearchBar from './Components/SearchBar/SearchBar';
import Button from './Components/Utils/Button/Button';
import NotificationItem from './Components/Notifications/NotificationItem';
import EvaluateArea from './Routes/Evaluate/EvaluateArea/EvaluateArea';
import RouteWrapper from './Components/Utils/RouteWrapper/RouteWrapper';
//Routes
import Dashboard from './Routes/Dashboard';
import Evaluate from './Routes/Evaluate/Evaluate';
import Improve from './Routes/Improve'
import Develop from './Routes/Develop';
import Library from './Routes/Library';
import Resources from './Routes/Resources';
import Settings from './Routes/Settings';
import Account from './Routes/Account';
import QualityCalendar from './Routes/QualityCalendar';
import AnalyseBot from './Routes/AnalyseBot/AnalyseBot';
import Portal from './Routes/Portal/Portal';
import { DrawerProvider } from './Components/SectionsDrawer/DrawerContext';
import ReduxTest from './Routes/ReduxTest';
import { RootState, useAppDispatch, useAppSelector } from './Redux/Store/Store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearUser, setUser } from './Redux/Store/Slices/auth';
import TemplateEditor from './Routes/Portal/TemplateEditor/TemplateEditor';
import DefineFields from './Routes/Portal/TemplateEditor/DefineFields/DefineFields';
import DefineStructure from './Routes/Portal/TemplateEditor/DefineStructure/DefineStructure';
import DefineQuestions from './Routes/Portal/TemplateEditor/DefineQuestions/DefineQuestions';

function App() {

  const { user, isAuthenticated, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();
  const dispatch = useAppDispatch();
  
  const { setIsOpen } = useTour();
  
    // Check the Redux auth state
    const reduxAuth = useSelector((state: RootState) => state.auth);

    useEffect(() => {
      const setupUser = async () => {
        if (isAuthenticated && user) {
          try {
            const idTokenClaims = await getIdTokenClaims();
            const userData = {
              id: user.sub || '', // Ensure `id` is never `undefined`
              sub: user.sub || '',
              crispId: '', // Additional data as needed
              archived: false,
              likedTemplateIds: [],
              email: user.email ?? '', // Use nullish coalescing in case `email` is undefined
              givenName: user.given_name,
              familyName: user.family_name,
              fullName: user.name,
              picture: user.picture,
              lastLogin: new Date(), // Replace with real data if tracking login time
              receiveEmails: true, // Adjust based on your data needs
            };
            dispatch(setUser(userData)); // Dispatch to Redux
          } catch (error) {
            console.error('Error fetching token claims:', error);
            dispatch(clearUser());
          }
        } else {
          dispatch(clearUser());
        }
      };
  
      setupUser();
    }, [isAuthenticated, user, dispatch, getIdTokenClaims]);  

  const data = ["Evalute", "Improve", "Develop", "Library", "Resources", "Settings", "Admin"]

  if (isAuthenticated) {
    return (
      <div className='wrapper'>
        <Navbar />
        <div className='container'>
          <div className='top-nav'>
            <div className='top-nav__left'>
              <SearchBar type='' data={data} />
            </div>
            <div className='top-nav__right'>
              <Button className='evaluate-tour-1' clickHandler={() => logout()} size="Icon" colour={"rgba(0,0,0,0"}>
                <img src='/img/NavIcons/logout.svg' />
              </Button>
              <Button className='tour-button-container' clickHandler={() => setIsOpen(true)} size="Icon" colour={"rgba(0,0,0,0"}>
                <img src='/img/NavIcons/product.svg' />
              </Button>
              <NotificationItem />
            </div>
          </div>
          <div className="main">
            <RouteWrapper>
              <Routes>

                <Route path="/" element={<Dashboard />} index />
                <Route path="/calendar" element={<QualityCalendar />} />

                <Route path="/evaluate" element={<Evaluate />}>
                  <Route path="active" />
                  <Route path="planned" />
                  <Route path="archive" />
                </Route>

                  <Route path="evaluate/:areaId" element={<EvaluateArea />}>
                    <Route path=":sectionId/:criteriaId"/>
                  </Route>

                <Route path="/portal/template-editor/:templateId/:templateTitle/define-structure" element={<DefineStructure />} />
      
                <Route path="/portal/template-editor/:templateId/:templateTitle/define-fields" element={<DefineFields />} />
                <Route path="/portal/template-editor/:templateId/:templateTitle/define-questions" element={<DefineQuestions />} >
                  <Route path=':sectionId/:criteriaId'/>
                </Route>
                

                <Route path="/improve" element={<Improve />} />
                <Route path="/develop" element={<Develop />} />
                <Route path="/library" element={<Library />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/portal" element={<Portal />}>
                  <Route path="active" />
                  <Route path="archive" />
                </Route>
                <Route path="/template-editor" element={<TemplateEditor />}>
                  <Route path="active" />
                  <Route path="archive" />
                </Route>
                <Route path="/settings" element={<Settings />} />
                <Route path="/account" element={<Account />} />
                <Route path="/analyse-bot" element={<AnalyseBot />} />

              </Routes>
            </RouteWrapper>
          </div>
        </div>
      </div >
    );
  } else {
    return <AuthLogin />
  }

}

export default App;
