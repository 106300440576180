import React, { useEffect } from 'react';
import SectionsDrawerItem from './SectionsDrawerItem';
import { IDrawerItem, IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';

type SectionsDrawerProps = {
  sections: IDrawerSection[];
  basePath: string;
  activeSectionId: string;
  activeItemId: string;
  onClickHandler?: (item: IDrawerItem) => void;
};

const SectionsDrawer: React.FC<SectionsDrawerProps> = ({
  sections,
  basePath,
  activeSectionId,
  activeItemId,
  onClickHandler
}) => {
  
  return (
    <div>
      {sections.map((section, index) => (
        <div key={index}>
          <SectionsDrawerItem
            key={section.id}
            section={section}
            activeSection={section.id === activeSectionId}
            activeItemId={activeItemId}
            basePath={basePath}
            onClickHandler={onClickHandler}
          />
        </div>
      ))}
    </div>
  );
};

export default SectionsDrawer;