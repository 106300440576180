import React from 'react'
import Dictionary from '../../../Classes/Dictionary'
import styles from './CompletionStatus.module.css'
import effects from '../../../Modules/effects.module.css'

type CompletionStatusProps = {
    status: "Complete" | "Incomplete" | "In Progress" | "Overdue"
}

const CompletionStatus = ({status} : CompletionStatusProps) => {

    const colours = new Dictionary<string, string>([
        ["Complete", "#52BFA2"],
        ["Incomplete", "#CFDCE5"],
        ["In Progress", "#EAB33C"],
        ["Overdue", "#E25A74"],
    ]);

    const textColours = new Dictionary<string, string>([
      ["Complete", "#FFF"],
      ["Incomplete", "#000"],
      ["In Progress", "#000"],
      ["Overdue", "#000"],
  ]);

  return (
    <div className={`${styles.status} ${effects.shadow}`} style={{background: colours.tryGetValue(status, "#FFF"), color: textColours.tryGetValue(status, "#000")}}>{status}</div>
  )
}

export default CompletionStatus