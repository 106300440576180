import React, { useState } from 'react'
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom'
//CSS
import styles from '../Routes.module.css'
import layout from '../../Modules/layout.module.css'
import Button from '../../Components/Utils/Button/Button'
//Components
import Card from '../../Components/Evaluate/Card/Card'
import TabbedView from '../../Components/Utils/TabbedView/TabbedView'
import { ITab } from '../../Types/ITab'
import RouteWrapper from '../../Components/Utils/RouteWrapper/RouteWrapper'
import EvaluateActive from './EvaluateActive'
import EvaluatePlanned from './EvaluatePlanned'
import EvaluateArchive from './EvaluateArchive'
import { selectSectionById, selectCriteriaById, setQuestionnaire } from '../../Redux/Store/Slices/questionnaire'
import { useAppDispatch, useAppSelector } from '../../Redux/Store/Store'
import { ImportQuestionnaireTemplate } from '../../API/TemplateBank/TemplateBank'
import { IProductQuestionnaire } from '../../Types/Products/IProductQuestionnaire'
import { useDispatch } from 'react-redux'
import Popup from '../../Components/Utils/Popup/Popup'
import ImportTemplate from '../../Components/Products/ImportTemplate/ImportTemplate'

const tabs: ITab[] = [
  {
    title: "Active",
    active: true,
    url: "/evaluate/active"
  },
  {
    title: "Planned",
    active: false,
    url: "/evaluate/planned"
  },
  {
    title: "Archive",
    active: false,
    url: "/evaluate/archive"
  }
]

const Evaluate = () => {

  const navigate = useNavigate();
  
  const dispatch = useDispatch();  

    const [showImportTemplate, setShowImportTemplate] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<ITab>(tabs[0]);


    const handleImportTemplate = (templateId: string) => {
      const importTemplate = async () => {
        const questionnaire = await ImportQuestionnaireTemplate(templateId)
        dispatch(setQuestionnaire(questionnaire));  
        navigate(`/evaluate/${questionnaire.id}`)
      }
      importTemplate();
    }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Evaluate</h1>
        <Button colour="#EAB33C" size="Small" textColour="#000" id="tour-new-activity" clickHandler={() => setShowImportTemplate(true)}>New Activity</Button>
      </div>
      <TabbedView data={tabs} activeTab={activeTab} activeTabChanged={setActiveTab} />
      <div className={styles.body}>
        {
          <Routes>
            <Route path="active" element={<EvaluateActive />} />
            <Route path="planned" element={<EvaluatePlanned />} />
            <Route path="archive" element={<EvaluateArchive />} />
          </Routes>
        }
      </div>

      <Popup style={{height: "150px"}} isVisible={showImportTemplate} onClose={() => setShowImportTemplate(!showImportTemplate)}>
        <div className={layout.padding10}>
          <ImportTemplate importHandler={handleImportTemplate}/>
        </div>
      </Popup>

      {/*



<Routes>
            <Route path="/moreevaluate" element={<Evaluate />}/>
          </Routes>


        <Card title="Provider T Level Plan V4" percentage={100} />
        <Card title="Ofsted Education Inspection Framework FES Long V5" percentage={34} />
        <Card title="Provider T Level Plan V2" percentage={82} />
        <Card title="DFE T Level Plan V4" percentage={61} />

*/}

    </div>
  )
}

export default Evaluate