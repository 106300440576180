export interface IDynamicField {
    id: string;
    title: string;
    content: string;
    type: FieldType;
    specialField: SpecialField;
    archived: boolean;
    mandatory: boolean;
}

export interface IDynamicFieldPrototype extends IDynamicField {  
    templateId: string | undefined;
} 

export enum FieldType {
    Text = 0,
    Number = 1,
    Date = 2,
    Time = 3,
    DateTime = 4,
    Boolean = 5,
    Select = 6,
    MultiSelect = 7,
    Radio = 8,
    Checkbox = 9
}

export enum SpecialField {
    None = 0,
    //Title = 1,
    //FullQuestion = 2,
    WhatWeDo = 3,
    WhatWeDoWell = 4,
    AreasForImprovement = 5,
    Text1 = 6,
    Text2 = 7,
    Text3 = 8,
}

