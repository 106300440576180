import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// CSS
import styles from './TabbedView.module.css';
import { ITab } from '../../../Types/ITab';
// Components
import Tab from './Tab';

type TabbedViewProps = {
    data: ITab[];
    activeTab: ITab;
    activeTabChanged: (tab: ITab) => void;
};

const TabbedView = ({ data, activeTab, activeTabChanged }: TabbedViewProps) => {

    return (
        <div className={styles.container}>
            {data.map((item, index) => (
                <Link key={index} to={item.url} onClick={() => activeTabChanged(item)}>
                    <Tab
                        key={index}
                        title={item.title}
                        active={item === activeTab}
                        clickHandler={() => {}}
                    />
                </Link>
            ))}
        </div>
    );
};

export default TabbedView;
