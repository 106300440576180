import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './QuestionSectionCriteria.module.css';
import layout from '../../Modules/layout.module.css'
import effects from '../../Modules/effects.module.css'
import CompletionStatus from '../Utils/CompletionStatus/CompletionStatus';
import Paging from '../Utils/Paging/Paging';
import Button from '../Utils/Button/Button';
import TagsBar from '../Utils/TagsBar/TagsBar';
import QuestionSectionTextArea from './QuestionSectionTextArea';
import QuestionSectionResults from './QuestionSectionResults';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/Store';
import { generateAssessment, selectCriteriaById, selectQuestionById, selectSectionById, setActiveCriteria, setActiveQuestionnaireSection, UpdateCriteriaDate, updateQuestionnaireGrade, updateQuestionnaireQuestion } from '../../Redux/Store/Slices/questionnaire';
import Form from '../Utils/Form/Form';
import FloatingLabelTextArea from '../Utils/Form/FloatingLabelTextArea/FloatingLabelTextArea';
import GradeSelector from '../Utils/GradeSelector/GradeSelector';
import DatePicker from '../Utils/DatePicker/DatePicker';

const pageSize = 6;

const QuestionSectionCriteria = () => {
  const { itemId, sectionId, criteriaId } = useParams<{ itemId?: string; sectionId?: string; criteriaId?: string; }>();

  const dispatch = useAppDispatch();
  const { activeCriteriaId, activeSectionId, questionnaire } = useAppSelector(state => state.questionnaire);

  const section = useAppSelector(selectSectionById(activeSectionId));
  const criteria = useAppSelector(selectCriteriaById(activeSectionId, activeCriteriaId));

  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isViewingResults, setIsViewingResults] = useState<boolean>(false);
  const [generatingAssessment, setGeneratingAssessment] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  // check when criteria updates
  useEffect(() => {
    if (criteria) {
      console.log("Criteria updated");
      //criteria date log
      console.log(criteria.date);
      
    }
  }, [criteria]);

  useEffect(() => {
    if (sectionId && sectionId !== activeSectionId) {
      dispatch(setActiveQuestionnaireSection({ sectionId }));
    }
  }, [dispatch, sectionId, activeSectionId]);

  useEffect(() => {

    const init = async () => {

      if (itemId && itemId !== activeCriteriaId) {
        await dispatch(setActiveCriteria({ itemId: itemId }));
      }
    }
    init();
    
  }, [dispatch, itemId, activeCriteriaId]);

  useEffect(() => {
    if(questionnaire?.legacyVersion){
      setIsViewingResults(true)
    }
    else{
      const pages = Math.ceil((criteria?.questions?.length ?? 0) / pageSize); 
      console.log(`Page Count: ${pages} Questions Length: ${criteria?.questions?.length} Page Size: ${pageSize}`)
      setPageCount(pages);
      setActivePage(1);
      setIsViewingResults(criteria?.generatedResults ?? false);
    }
    
  }, [activeCriteriaId]);

  const nextPageClickHandler = async () => {
    if(isViewingResults){
      setIsViewingResults(false)
    }
    else if (activePage === pageCount) {
      setIsViewingResults(true)
      setActivePage(1)

      if(!criteria?.generatedResults){
        await generateAssessmentHandler();
      }
    }
    else {
      setActivePage(activePage + 1)
    }
  }

  const generateAssessmentHandler = async () => {
    setGeneratingAssessment(true);
    await dispatch(generateAssessment());
    setGeneratingAssessment(false);
  }

  if(criteria && section){

    return (

      <div className={layout.container}>
    
        <div className={layout.column2}>

        <section className={layout.column1}>
          <div className={layout.row}>
            <div className={layout.row2}>
              <h2>{section.title}</h2>
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 48 48" width="25"><path d="M0 0h48v48h-48z" fill="none" /><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" /></svg>
            </div>
            <div className={`${layout.row2} ${layout.rowReverse}`}>
              <div>
                <CompletionStatus status={section.completionStatus ?? "Incomplete"} />
              </div>
              {!isViewingResults && 
              <h4>
                Page {activePage} of {pageCount}
              </h4>}
              
            </div>
          </div>
          <div className={layout.rowSpan40}>
            <p className={styles.description}>{criteria?.title}</p>
            <div className={`${layout.flexRow} ${layout.gap10} ${layout.centered}`}>
              Date:
              <DatePicker value={criteria.date} onChange={(date) => dispatch(UpdateCriteriaDate({ criteriaId: activeCriteriaId, sectionId: activeSectionId, date }))}></DatePicker>
            </div>
          </div>
        </section>

            {!isViewingResults ? 
            <>
              
              <section className={layout.column2}>

                <Form>
                  {criteria?.questions.slice(activePage * pageSize - pageSize, activePage * pageSize).map((item) => {
                    return <div key={criteria.questions.indexOf(item)} className={layout.column1}>
                      <FloatingLabelTextArea label={`${criteria.questions.indexOf(item) + 1}.   ${item.question}`} onChange={(e) => dispatch(updateQuestionnaireQuestion({...item, answer: e.target.value}))} value={item.answer} />
                    </div>
                  })}
                </Form>

                </section>
                <div className={`${layout.row} ${layout.rowReverse}`}>  
                  <Button colour={"#24394F"} clickHandler={nextPageClickHandler}>  
                    {criteria?.generatedResults   
                      ? (activePage === pageCount ? "View Results" : "Next")  
                      : (activePage === pageCount ? "Generate Assessment" : "Next")}  
                    {activePage === pageCount && <img src='/img/sparkle.svg' />}  
                  </Button>  
                </div>  
                  
                <Paging activePage={activePage} pageCount={pageCount} pageChanged={setActivePage} />
              </>
            : 
              <div className={layout.column2}>
            
                <section className={layout.column2}>
                  {
                    <QuestionSectionResults completed={completed} loading={generatingAssessment}/>
                  }
                  </section>
               
                <div className={`${layout.rowSpan40} ${layout.centered}`}>
                  <div className={`${layout.flexRow} ${layout.gap10} ${layout.centered} `}>
                    <h2>Grade: </h2>
                    <GradeSelector readOnly={false} grade={criteria?.grade}  />
                  </div>
                  <div className={layout.flex1}>
                    <TagsBar tags={criteria?.tags ?? ["no tags"]} />
                  </div>
                </div>

                <div className={`${layout.row1} ${layout.rowReverse}`}>
                  {!questionnaire?.legacyVersion && <Button colour={"#24394F"} clickHandler={nextPageClickHandler}>Back to questionnaire
                  </Button>}
                  {!questionnaire?.legacyVersion && <Button colour={"#24394F"} clickHandler={generateAssessmentHandler}>Generate Again
                    <img src='/img/sparkle.svg' />
                  </Button>}
                </div>
              </div>
            }
        </div>
        
      </div>
    )
  }
  else{

    return <></>
  }
  
}

export default QuestionSectionCriteria