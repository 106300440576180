import React, { useState } from 'react';  
import styles from '../../Routes.module.css';  
import layout from '../../../Modules/layout.module.css';  
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo';  
import { useNavigate } from 'react-router-dom';  
import { GetPagedQuestionnaireTemplates, CreateQuestionnaireTemplateByTitle, ToggleQuestionnaireTemplateArchivedById } from '../../../API/TemplateBank/TemplateBank';  
import CreationBar from '../../../Components/Utils/Actions/CreationBar/CreationBar';  
import PagedCardCollection from '../../../Components/Utils/LandingPages/Evaluate/PagedCardCollection';  
import { PagedRequest } from '../../../Types/Paging/PagedRequest';  
import { PagedResponse } from '../../../Types/Paging/PagedResponse';  
import SearchBar from '../../../Components/SearchBar/SearchBar';  
import { ITab } from '../../../Types/ITab';
import TabbedView from '../../../Components/Utils/TabbedView/TabbedView';


type TemplateBankItemsProps = {
  fetchData: (request: PagedRequest) => Promise<PagedResponse<AreaDisplayInfo>>;
  archived: boolean;
}
  
const TemplateBankItems = ({fetchData, archived}: TemplateBankItemsProps) => {  
  const navigate = useNavigate();  
  const [searchTerm, setSearchTerm] = useState<string>('');  
  
  const createTemplate = async (title: string) => {  
    const templateId = await CreateQuestionnaireTemplateByTitle(title);  
    navigate(`/portal/template-editor/${templateId}/${title}/define-fields`);  
  };  
  
  const handleSearchTermChange = (value: string) => {  
    setSearchTerm(value);  
  };  

  const toggleArchiveHandler = async (id: string) => {
    console.log(`Toggle archive for ${id}`);
    await ToggleQuestionnaireTemplateArchivedById(id);
  };
  
  return (  
    <div className={styles.container}>  
      <div className={styles.header}>  
        <h1>Template Bank</h1>  
      </div>  
      <div className={styles.body}>  
        <div className={layout.column2}>  
          <div className={layout.rowSpan}>  
            <p>Use the assistance of AI to create and edit questionnaires for Self Assessment templates.</p>  
          </div>  
          <SearchBar value={searchTerm} onChange={handleSearchTermChange} type="" />  
          <PagedCardCollection toggleArchiveHandler={toggleArchiveHandler} searchTerm={searchTerm} template={true} fetchData={fetchData} archived={archived} />  
          <CreationBar onCreateHandler={createTemplate} placeholder={'Enter title for new template'} noTextMessage={'The Title field is required'} />  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default TemplateBankItems;  