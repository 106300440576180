import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './SectionsDrawerItem.module.css';
import CompletionStatus from '../Utils/CompletionStatus/CompletionStatus';
import { IDrawerItem, IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';

type SectionsDrawerItemProps = {
  section: IDrawerSection;
  basePath: string;
  activeSection: boolean;
  activeItemId: string;
  onClickHandler?: (item: IDrawerItem) => void;
};

const SectionsDrawerItem: React.FC<SectionsDrawerItemProps> = ({
  section,
  basePath,
  activeSection,
  activeItemId,
  onClickHandler
}) => {
  
  const content = (
    <div className={`${styles.container} ${activeSection && styles.active}`}>
      <div className={styles.titleContainer}>
        <h3>{section.title}</h3>
        <CompletionStatus status={section.status ?? 'Incomplete'} />
      </div>
      <div className={styles.criteriaItems}>
        {activeSection && (
          <div className={styles.criteriaItems}>
            {section.items.map(item => (
              <Link
                to={basePath + item.url}
                key={item.id}
              >
                <p
                  className={styles.item}
                  style={{ fontWeight: item.id === activeItemId ? '700' : '500', padding: '7px 0' }}
                >
                  {item.title}
                </p>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return activeSection ? content : (
    <Link to={basePath + section.items[0].url}>
      {content}
    </Link>
  );
};

export default SectionsDrawerItem;