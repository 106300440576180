import React, { useEffect, useState } from 'react';
//CSS
import styles from './Checkbox.module.css';
//Components
//Data

type CheckboxProps = {
    clickHandler: (x: boolean) => void,
    color?: string,
    value?: boolean
    disabled?: boolean
    children?: React.ReactNode
};

const Checkbox = ({ clickHandler, color, value, disabled, children } : CheckboxProps) => {

const [isChecked, setIsChecked] = useState<boolean>(value ?? false);

useEffect(() => {
  if(value && !disabled)
  setIsChecked(value)
}, [value])

  return (
    <div
        className={`${styles.container} ${isChecked ? styles.checked : ''}`}
        onClick={() => {
          if(disabled) return
          clickHandler(!isChecked); 
          setIsChecked(!isChecked); 
        }}
        style={{background: isChecked? color : undefined}}
    >
      {children? children : <img src='/img/tick.svg' />}
      
    </div>
  );
};

export default Checkbox;