import React from 'react';
import styles from './DatePicker.module.css';

interface DatePickerProps {
  label?: string;
  value?: Date | string;
  onChange?: (date: Date) => void;
}

// Helper to safely convert a value to a Date if possible
const parseDate = (value?: Date | string): Date | null => {
  if (!value) return null;
  if (value instanceof Date) {
    return isNaN(value.getTime()) ? null : value;
  }
  const date = new Date(value);
  return isNaN(date.getTime()) ? null : date;
};

const DatePicker: React.FC<DatePickerProps> = ({ label = "Select Date", value, onChange }) => {

  const formatDate = (date: Date): string => {
    // Format the date to YYYY-MM-DD
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const newDate = new Date(event.target.value);
      onChange(newDate);
    }
  };

  const dateValue = parseDate(value);
  const inputValue = dateValue ? formatDate(dateValue) : '';

  return (
    <div>
      <input
        type="date"
        id="date-input"
        value={inputValue}
        onChange={handleDateChange}
        pattern="\d{4}-\d{2}-\d{2}"
        className={styles.datePicker}
      />
    </div>
  );
};

export default DatePicker;
