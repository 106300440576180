import { ChangeEvent, useEffect, useRef, useState } from "react";  
import { useNavigate, useParams } from "react-router-dom";  
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Store";  
import { selectSectionById, selectCriteriaById, UpdateFieldContent, UpdateFieldContentPayload } from "../../Redux/Store/Slices/questionnaire";  
import layout from "../../Modules/layout.module.css";  
import styles from "./QuestionSectionCriteria.module.css";  
import Skeleton from "../Utils/Skeleton/Skeleton";  
import QuestionSectionTextArea from "./QuestionSectionTextArea";  
import { UpdateDynamicFieldContentById, UpdateEvaluateFieldByCriteriaIdAndActivityId } from "../../API/Evaluate/Evaluate";  
import { FieldType, IDynamicField, SpecialField } from "../../Types/Products/IDynamicField";  
import DatePicker from "../Utils/DatePicker/DatePicker";  
import NumberInput from "../Utils/NumberInput/NumberInput";  
import MultiSelect, { ISelectOption } from "../Utils/MultiSelect/MultiSelect";  
import PickList from "../Utils/MultiSelect/MultiSelect";  
import Form from "../Utils/Form/Form";  
import FloatingLabelTextArea from "../Utils/Form/FloatingLabelTextArea/FloatingLabelTextArea";  
import DropDownList from "../Utils/DropDownList/DropDownList";
import FloatingLabelMultiSelect from "../Utils/Form/FloatingLabelMultiSelect/FloatingLabelMultiSelect";
import FloatingLabelDatePicker from "../Utils/Form/FloatingLabelDatePicker/FloatingLabelDatePicker";
  
const options: ISelectOption[] = [  
    { value: 'apple', label: 'Apple' },  
    { value: 'banana', label: 'Banana' },  
    { value: 'cherry', label: 'Cherry' },  
    { value: 'date', label: 'Date' },  
    { value: 'elderberry', label: 'Elderberry' },  
];  
  
type QuestionSectionResultsProps = {  
    completed: boolean;  
    loading: boolean;  
};  
  
const debounceDelay = 500;  
  
const QuestionSectionResults = ({ completed, loading }: QuestionSectionResultsProps) => {  
    const navigate = useNavigate();  
    const dispatch = useAppDispatch();  
    const { areaId } = useParams();  
    const { activeCriteriaId, activeSectionId, questionnaire } = useAppSelector((state) => state.questionnaire);  
    const section = useAppSelector(selectSectionById(activeSectionId));  
    const criteria = useAppSelector(selectCriteriaById(activeSectionId, activeCriteriaId));  
  
    const [selectedFruits, setSelectedFruits] = useState<ISelectOption[]>([]);  
    const handleSelectionChange = (newSelectedOptions: ISelectOption[]) => {  
        setSelectedFruits(newSelectedOptions);  
    };  
  
    // Local state to manage field content  
    const [localFields, setLocalFields] = useState(() => {  
        return criteria?.fields?.reduce((acc, field) => {  
            acc[field.id] = field.content;  
            return acc;  
        }, {} as { [key: string]: string }) || {};  
    });  
  
    const localFieldsRef = useRef(localFields);  
  
    useEffect(() => {  
        localFieldsRef.current = localFields; // Keep the ref updated with the latest state  
    }, [localFields]);  
  
    useEffect(() => {  
        console.log("Current localFields:", localFields);  
    }, [localFields]);  

      
    useEffect(() => {  
      const fields = criteria?.fields?.reduce((acc, field) => {  
        acc[field.id] = field.content;  
        return acc;  
      }, {} as { [key: string]: string }) || {}

      setLocalFields(fields)
    }, [criteria]);  
  
    const updateTimer = useRef<NodeJS.Timeout | null>(null);  
  
    const handleInputChange = (fieldId: string, content: string) => {  
        setLocalFields((prev) => ({ ...prev, [fieldId]: content }));  
  
        if (updateTimer.current !== null) {  
            clearTimeout(updateTimer.current);  
        }  
  
        updateTimer.current = setTimeout(async () => {  
            try {  
                const field = criteria?.fields.find((x) => x.id === fieldId);  
  
                if (field) {  
                    const updatedField: IDynamicField = {  
                        ...field,  
                        content: localFieldsRef.current[fieldId], // Use the latest value from the ref  
                    };  
  
                    if (criteria?.id && areaId) {  
                        await UpdateEvaluateFieldByCriteriaIdAndActivityId(updatedField, criteria.id, areaId);  
                    }  
                }  
            } catch (error) {  
                console.error(error);  
            }  
        }, debounceDelay);  
    };  
  
    // Dispatch update action on blur  
    const handleBlur = (fieldId: string) => {  
        dispatch(UpdateFieldContent({ fieldId, content: localFields[fieldId] }));  
    };  
  
    return (  
        <section className={layout.column2}>  
            <Form>  
                {criteria?.fields.map((field, index) => {  
                    return (  
                        <div key={index} className={layout.column1}>  
                            {field.type === FieldType.Text && 
                                <FloatingLabelTextArea  
                                label={field.title}  
                                onChange={(e) => handleInputChange(field.id, e.target.value)} // Passing the entire event  
                                value={localFields[field.id] || ""} // Ensuring value comes from the local state  
                                onBlur={() => handleBlur(field.id)}/>  
                            }
                        </div>  
                    );  
                })}  
            </Form>  
        </section>  
    );  
};  
  
export default QuestionSectionResults;  