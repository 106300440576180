import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AreaDisplayInfo } from "../../../Types/AreaDisplayInfo/AreaDisplayInfo"
import { SetArchivedEvaluateAreaRequest } from "../../../Types/Evaluate/SetArchivedEvaluateAreaRequest";
import { axiosInstance } from "../../../Authentication/AxiosInterceptor";
import { PagedRequest } from "../../../Types/Paging/PagedRequest";
import { PagedResponse } from "../../../Types/Paging/PagedResponse";
import { toast } from "react-toastify";

interface AreaState {
    areas: AreaDisplayInfo[],
    totalCount: number
}

const initialState: AreaState  = {
    areas: [],
    totalCount: 0
}

// Thunk to handle API call and update the state
export const SetArchivedEvaluateAreaById = createAsyncThunk(
    "area/setArchivedEvaluateAreaById",
    async (body: SetArchivedEvaluateAreaRequest, thunkAPI) => {
      try {
        const response = await axiosInstance.post(`SetArchivedEvaluateAreaById`, body);
        return body; // Return the payload to update the state
      } catch (error) {
        console.error(error);
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  // Thunk to fetch paged area display info
export const GetPagedEvaluateAreaDisplayInfosByClientAndUser = createAsyncThunk(
    "area/getPagedEvaluateAreaDisplayInfosByClientAndUser",
    async (request : PagedRequest, thunkAPI) => {
      try {
        const response = await axiosInstance.put(
          `GetPagedEvaluateAreaDisplayInfosByClientAndUser/${process.env.REACT_APP_CLIENT_ID}/`,
          request
        );
        return response.data as PagedResponse<AreaDisplayInfo>; // Return the paged response
      } catch (error) {
        console.error(error);
        toast.error(`Unable to load areas. Please try again later.`)
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  // Thunk to fetch archived paged area display info
export const GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser = createAsyncThunk(
    `area/GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser/${process.env.REACT_APP_CLIENT_ID}/`,
    async (request: PagedRequest, thunkAPI) => {
      try {
        const response = await axiosInstance.put(
          `GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser/${process.env.REACT_APP_CLIENT_ID}/`,
          request
        );
        return response.data as PagedResponse<AreaDisplayInfo>; // Return the paged response
      } catch (error) {
        console.error(error);
        toast.error(`Unable to load archived areas. Please try again later.`)
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
  
  export const AreaSlice = createSlice({
    name: "Area",
    initialState,
    reducers: {
      addArea: (state, action: PayloadAction<{ name: string }>) => {
        console.log("Area added to state.");
      },
    },
    extraReducers: (builder) => {
        builder.addCase(SetArchivedEvaluateAreaById.fulfilled, (state, action) => {
            const { areaId, archived } = action.payload;
          
              state.areas = state.areas.filter((area) => area.id !== areaId);
              // Otherwise, just update the `archived` status
              const areaIndex = state.areas.findIndex((area) => area.id === areaId);
              if (areaIndex !== -1) {
                state.areas[areaIndex].archived = archived;
              }
            
          });
          
  
      builder.addCase(SetArchivedEvaluateAreaById.rejected, (state, action) => {
        console.error("Failed to set archived status:", action.payload);
      });

      builder.addCase(GetPagedEvaluateAreaDisplayInfosByClientAndUser.fulfilled, (state, action) => {
        const { items, totalCount } = action.payload;
  
        // Update the areas and total count in the state
        state.areas = items;
        state.totalCount = totalCount;
      });
  
      builder.addCase(GetPagedEvaluateAreaDisplayInfosByClientAndUser.rejected, (state, action) => {
        console.error("Failed to fetch area display info:", action.payload);
      });

      builder.addCase(GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser.fulfilled, (state, action) => {
        const { items, totalCount } = action.payload;
  
        // Update the areas and total count in the state
        state.areas = items;
        state.totalCount = totalCount;
      });
  
      builder.addCase(GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser.rejected, (state, action) => {
        console.error("Failed to fetch archived area display info:", action.payload);
      });
    },
  });
  
  export default AreaSlice.reducer;
  export const { addArea } = AreaSlice.actions;