import React, { useState } from 'react';  
import PagedCardCollection from '../../Components/Utils/LandingPages/Evaluate/PagedCardCollection';  
import { GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser } from '../../Redux/Store/Slices/area';  
import { useAppDispatch } from '../../Redux/Store/Store';  
import { AreaDisplayInfo } from '../../Types/AreaDisplayInfo/AreaDisplayInfo';  
import { PagedRequest } from '../../Types/Paging/PagedRequest';  
import { PagedResponse } from '../../Types/Paging/PagedResponse';  
import SearchBar from '../../Components/SearchBar/SearchBar';  
  
const EvaluateArchive: React.FC = () => {  
    const dispatch = useAppDispatch();  
    const [searchTerm, setSearchTerm] = useState<string>('');  
  
    const fetchData = async (request: PagedRequest): Promise<PagedResponse<AreaDisplayInfo>> => {  
        const result = await dispatch(GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser(request));  
        return result.payload as PagedResponse<AreaDisplayInfo>;  
    };  
  
    const handleSearchTermChange = (value: string) => {  
        setSearchTerm(value);  
    };  
  
    return (  
        <div>  
            <SearchBar value={searchTerm} onChange={handleSearchTermChange} type="" />  
            <PagedCardCollection  
                template={false}  
                fetchData={fetchData}  
                archived={true}  
                searchTerm={searchTerm}  
            />  
        </div>  
    );  
};  
  
export default EvaluateArchive;  