import React, { useState, useEffect } from 'react';  
import { Link, useParams } from 'react-router-dom';  
import TitledField from '../../../../Components/Utils/TitledField/TitledField';  
import CreationBar from '../../../../Components/Utils/Actions/CreationBar/CreationBar';  
import ActionBar from '../../../../Components/Utils/Actions/ActionBar/ActionBar';  
import layout from '../../../../Modules/layout.module.css';  
import { FieldType, IDynamicField, IDynamicFieldPrototype, SpecialField } from '../../../../Types/Products/IDynamicField';  
import Button from '../../../../Components/Utils/Button/Button';
import styles from './DefineFields.module.css'
import { GetFieldPrototypesByTemplateId, CreateFieldPrototype, DeleteFieldPrototypesByIdList, UpdateFieldPrototype } from '../../../../API/TemplateBank/TemplateBank';
import { title } from 'process';
import MultiCreationBar, { FieldTypeInfo } from '../../../../Components/Utils/Actions/MultiCreationBar/MultiCreationBar';
import { GetFieldTypeName } from '../../../../Data/FieldTypesData';

const fieldTypes : FieldTypeInfo[] = [
  { title: 'Text', type: FieldType.Text },
  { title: 'Date', type: FieldType.Date }
];


  
type DefineFieldsProps = object;  
  
const DefineFields = ({}: DefineFieldsProps) => {  
  const { templateId, templateTitle } = useParams();  

  /*const temp: IDynamicFieldPrototype[] = [  
    {
      id: "1",
      templateId: undefined,
      title: "What We Do",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.None,
      archived: false
    },  
    {
      id: "2",
      templateId: undefined,
      title: "What We Do Well",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.None,
      archived: false
    },  
    {
      id: "3",
      templateId: undefined,
      title: "Areas For Improvement",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.AreasForImprovement,
      archived: false
    }  
  ];  */
  
  const [selectedItems, setSelectedItems] = useState<string[]>([]);  
  const [fields, setDynamicFields] = useState<IDynamicFieldPrototype[]>([]);  
  
  useEffect(() => {  

    if(templateId){
      const getFields = async () => {
        const response = await GetFieldPrototypesByTemplateId(templateId);
        setDynamicFields(response);
      }
      getFields();
    }
    
  }, [templateId]);  
  
  const itemSelected = (itemId: string, selected: boolean) => {  
    setSelectedItems((prevState) => {  
      if (selected) {  
        // Add itemId to the list  
        return [...prevState, itemId];  
      } else {  
        // Remove itemId from the list  
        return prevState.filter((id) => id !== itemId);  
      }  
    });  
  };  
  
  const handleTitleChange = async (itemId: string, newTitle: string) => {  

    const field = fields.find((field) => field.id === itemId);
    if(field){
      const updatedField = { ...field, title: newTitle };
      await UpdateFieldPrototype(updatedField);
    }

    setDynamicFields((prevFields) =>  
      prevFields.map((field) =>  
        field.id === itemId ? { ...field, title: newTitle } : field  
      )  
    );  
  };  
  
  const handleTextChange = async (itemId: string, text: string) => {  

    const field = fields.find((field) => field.id === itemId);
    if(field){
      const updatedField = { ...field, content: text };
      await UpdateFieldPrototype(updatedField);
    }
    
    setDynamicFields((prevFields) =>  
      prevFields.map((field) =>  
        field.id === itemId ? { ...field, content: text } : field  
      )  
    );  
  };  
  
  const getNextAvailableSpecialField = () => {  
    const usedSpecialFields = fields.map(field => field.specialField);  
    const specialFieldValues = Object.values(SpecialField).filter(value => typeof value === 'number') as number[];  

    for (const value of specialFieldValues) {  
        if (!usedSpecialFields.includes(value) && value !== SpecialField.None) {  
            return value;  
        }  
    }  
    return SpecialField.None; // Default to None if all special fields are used  
};  

const createItem = async (title: string) => {  
    if (templateId) {  
        const specialField = getNextAvailableSpecialField();  

        if (specialField === SpecialField.None) {  
            alert('All fields are used');  
            return;  
        }

        const newField = await CreateFieldPrototype(title, FieldType.Text, templateId, specialField);  
        setDynamicFields([...fields, newField]);  
    }  
};  

  const deleteItems = async () => {  

    await DeleteFieldPrototypesByIdList(selectedItems);

    setDynamicFields((prevFields) =>  
      prevFields.filter((field) => !selectedItems.includes(field.id)) 
    );  

    setSelectedItems([]); // Clear the selected items after deletion  
  };  
  
  return (  
    <div className={layout.container}>  
      <div className={layout.column1}>  
        <div className={`${layout.column1}`}>
          <h4>{templateTitle}</h4>    
          <p className={layout.fontMedium}>Create the fields for this Self Assessment</p>     
        </div>    

        <ActionBar enabledFeatures={['delete']} selectedCount={selectedItems.length} onDeleteHandler={deleteItems}/>  

        {fields.map(({ id, title, content, mandatory, type, specialField }) => {  
          return (  
            <TitledField  
              key={id}  
              title={title}  
              text={content}  
              onSelectedHandler={(selected: boolean) => itemSelected(id, selected)}  
              titleChanged={(newTitle: string) => handleTitleChange(id, newTitle)}  
              valueChanged={(text: string) => handleTextChange(id, text)}  
              locked={mandatory}
              specialField={specialField}
            />  
          );  
        })}  
        <p></p>  

        <CreationBar onCreateHandler={createItem} placeholder={'Enter title of new field...'} noTextMessage={'*'}/>

        <p></p>  

        <div className={`${layout.column1} ${layout.container}`}>       
          <div className={layout.rowReverse}><p>Next please define the structure of your questionnaire</p></div>          
          <div className={`${layout.rowSpan}`}>   
            <Link to={`/portal`}>
              <Button>Exit: Portal</Button>
            </Link>
            <Link to={`/portal/template-editor/${templateId}/${templateTitle}/define-structure`}>
              <Button>Next</Button>
            </Link>            
          </div>            
        </div>          
      </div>  
    </div>  
  );  
};  
  
export default DefineFields;  