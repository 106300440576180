import React, { useState, useEffect } from 'react';  
import { Link, useParams } from 'react-router-dom';  
import TitledField from '../../../../../Components/Utils/TitledField/TitledField';  
import CreationBar from '../../../../../Components/Utils/Actions/CreationBar/CreationBar';  
import ActionBar from '../../../../../Components/Utils/Actions/ActionBar/ActionBar';  
import layout from '../../../../../Modules/layout.module.css';  
import { GetFieldPrototypesByTemplateId, CreateFieldPrototype, DeleteFieldPrototypesByIdList, GetQuestionsByTemplateIdAndCriteriaId, CreateQuestionInQuestionnaireTemplate, DeleteQuestionsInCriteriaTemplate } from '../../../../../API/TemplateBank/TemplateBank';
import { IQuestionnaireQuestion } from '../../../../../Types/Products/IQuestionnaireQuestion';
  
type DefineQuestionsProps = object;  
  
const QuestionsView = ({}: DefineQuestionsProps) => {  
  const { templateId, templateTitle, sectionId, criteriaId } = useParams();  
  
  const [selectedItems, setSelectedItems] = useState<string[]>([]);  
  const [questions, setQuestions] = useState<IQuestionnaireQuestion[]>([]);  
  
  useEffect(() => {  

    if(templateId && criteriaId){
      const getFields = async () => {
        const response = await GetQuestionsByTemplateIdAndCriteriaId(templateId, criteriaId);
        setQuestions(response);
      }
      getFields();
    }
    
  }, [templateId, criteriaId]);  
  
  const itemSelected = (itemId: string, selected: boolean) => {  
    setSelectedItems((prevState) => {  
      if (selected) {  
        // Add itemId to the list  
        return [...prevState, itemId];  
      } else {  
        // Remove itemId from the list  
        return prevState.filter((id) => id !== itemId);  
      }  
    });  
  };  
  
  const handleTitleChange = (itemId: string, newTitle: string) => {  
    setQuestions((prev) =>  
        prev.map((field) =>  
        field.id === itemId ? { ...field, title: newTitle } : field  
      )  
    );  
  };  
  
  const handleTextChange = (itemId: string, text: string) => {  
    setQuestions((prev) =>  
        prev.map((item) =>  
            item.id === itemId ? { ...item, content: text } : item  
      )  
    );  
  };  
  
  const createItem = async (questionText: string) => {  
    if(templateId && sectionId && criteriaId){
      const newQuestion = await CreateQuestionInQuestionnaireTemplate(templateId, sectionId, criteriaId, questionText);
      setQuestions([...questions, newQuestion]);  
    }
  };  

  const deleteItems = async () => {  

    if(templateId && sectionId && criteriaId){
        await DeleteQuestionsInCriteriaTemplate(templateId, sectionId, criteriaId, selectedItems);

        setQuestions((prev) =>  
            prev.filter((item) => !selectedItems.includes(item.id)) 
        );  

        setSelectedItems([]); // Clear the selected items after deletion  
    }
  };  
  
  return (  
    <div className={layout.container}>  
      <div className={layout.column1}>  
        <div className={`${layout.column1}`}>
          <h4>{templateTitle}</h4>    
          <p className={layout.fontMedium}>Create questions for this questionnaire</p>     
        </div>    
        <ActionBar enabledFeatures={['delete']} selectedCount={selectedItems.length} onDeleteHandler={deleteItems}/>  

        {questions.map(({ id, question }, index) => {  
            return (  
                <TitledField  
                key={id}  
                title={`Q${index + 1}`} // Set title to Q followed by the index (1-based)  
                text={question}  
                onSelectedHandler={(selected: boolean) => itemSelected(id, selected)}  
                titleChanged={(newTitle: string) => handleTitleChange(id, newTitle)}  
                valueChanged={(text: string) => handleTextChange(id, text)}  
                />  
            );  
        })}  
        <p></p>  

        <CreationBar  
        onCreateHandler={createItem}  
        placeholder={'Enter new question...'}  
        noTextMessage={'*'}  
        />  
      </div>  
    </div>  
  );  
};  
  
export default QuestionsView;  