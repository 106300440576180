import { AxiosResponse } from "axios";
import { axiosInstance } from "../../Authentication/AxiosInterceptor";
import { AreaDisplayInfo } from "../../Types/AreaDisplayInfo/AreaDisplayInfo";
import { PagedResponse } from "../../Types/Paging/PagedResponse";
import { PagedRequest } from "../../Types/Paging/PagedRequest";
import { IQuestionnaireSectionResults } from "../../Types/Products/IQuestionnaireSectionResults";
import { IQuestionnaireQuestion } from "../../Types/Products/IQuestionnaireQuestion";
import { IProductQuestionnaire } from "../../Types/Products/IProductQuestionnaire";
import { IQuestionnaireSection } from "../../Types/Products/IQuestionnaireSection";
import { ICompletionStatus } from "../../Types/ICompletionStatus";
import { IQuestionnaireTemplate } from "../../Types/Products/IQuestionnaireTemplate";
import { SetArchivedEvaluateAreaRequest } from "../../Types/Evaluate/SetArchivedEvaluateAreaRequest";
import { ICriteriaItem } from "../../Types/Products/ICriteriaItemData";
import { ICriteriaBankItem } from "../../Types/TemplateBank/ICriteriaBankItem";
import { IDynamicField, IDynamicFieldPrototype } from "../../Types/Products/IDynamicField";




export async function GetPagedEvaluateAreasByUser(pageNumber: number, pageSize: number): Promise<PagedResponse<AreaDisplayInfo>>{

    try {
        const response: AxiosResponse<PagedResponse<AreaDisplayInfo>> = await axiosInstance.put(`GetPagedEvaluateAreaDisplayInfosByUser`, {
          pageNumber: pageNumber,
          pageSize: pageSize
        } as PagedRequest);
    
        return response.data;
       
      } catch (error) {
        console.log(error);
        throw error;
      }
}

/*
export async function GetPagedEvaluateAreaDisplayInfosByClientAndUser(pageNumber: number, pageSize: number): Promise<PagedResponse<AreaDisplayInfo>>{

    try {
        const response: AxiosResponse<PagedResponse<AreaDisplayInfo>> = await axiosInstance.put(`GetPagedEvaluateAreaDisplayInfosByClientAndUser/${process.env.REACT_APP_CLIENT_ID}/`, {
          pageNumber: pageNumber,
          pageSize: pageSize
        } as PagedRequest);
    
        return response.data;
       
      } catch (error) {
        console.log(error);
        throw error;
      }
}*/

/*
export async function GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser(pageNumber: number, pageSize: number): Promise<PagedResponse<AreaDisplayInfo>>{

    try {
        const response: AxiosResponse<PagedResponse<AreaDisplayInfo>> = await axiosInstance.put(`GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser/${process.env.REACT_APP_CLIENT_ID}/`, {
          pageNumber: pageNumber,
          pageSize: pageSize
        } as PagedRequest);
    
        return response.data;
       
      } catch (error) {
        console.log(error);
        throw error;
      }
}
      */

export async function GetPagedArchivedEvaluateAreasByUser(pageNumber: number, pageSize: number): Promise<PagedResponse<AreaDisplayInfo>>{

    try {

        const response: AxiosResponse<PagedResponse<AreaDisplayInfo>> = await axiosInstance.put(`GetPagedArchivedEvaluateAreaDisplayInfosByUser`, {
          pageNumber: pageNumber,
          pageSize: pageSize
        } as PagedRequest);
    
        return response.data;
       
      } catch (error) {
        console.log(error);
        throw error;
      }
}

export async function GetQuestionnaireByAreaId(areaId: string): Promise<IProductQuestionnaire>{

  const endpoint = `GetQuestionnaireByAreaId/${areaId}`;

  try {

      const response = await axiosInstance.get(endpoint);
  
      return response.data as IProductQuestionnaire;
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function GenerateAssessmentByQuestionnaireSectionIdAndCriteriaId(questionnaireSectionId: string, criteriaId: string): Promise<IQuestionnaireSectionResults>{

  const endpoint = `GenerateAssessmentByQuestionnaireSectionIdAndCriteriaId/${questionnaireSectionId}/${criteriaId}`;

  try {

      const response = await axiosInstance.get(endpoint);
  
      return response.data as IQuestionnaireSectionResults;
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateQuestionnaireQuestion(question: IQuestionnaireQuestion){

  try {

    const response = await axiosInstance.post("UpdateQuestionnaireQuestion", question);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateQuestionnaireGrade(questionnaireId: string, grade: string) {
  try {

    const response = await axiosInstance.patch(`UpdateQuestionnaireGrade/${questionnaireId}/${grade}`);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateQuestionnaireCriteriaStatus(criteriaId: string, status: ICompletionStatus){

  try {

    const response = await axiosInstance.patch(`UpdateQuestionnaireCriteriaStatus/${criteriaId}/${status}`);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateQuestionnaireSectionStatus(sectionId: string, status: ICompletionStatus){

  try {

    const response = await axiosInstance.patch(`UpdateQuestionnaireSectionStatus/${sectionId}/${status}`);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function GetQuestionnaireTemplateById(templateId: string): Promise<IQuestionnaireTemplate>{

    try {

      const response = await axiosInstance.patch(`GetQuestionnaireTemplateById/${templateId}`);

      return response.data as IQuestionnaireTemplate;
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function SetArchivedEvaluateAreaById(body: SetArchivedEvaluateAreaRequest) {
  try {
    const response = await axiosInstance.post(`SetArchivedEvaluateAreaById`, body)
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export async function UpdateDynamicField(field: IDynamicField){

  try {

    const response = await axiosInstance.post("UpdateDynamicField", field);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateDynamicFieldContentById(content: string, fieldId: string){

  try {

    const response = await axiosInstance.post(`UpdateDynamicFieldContentById/${fieldId}`, content);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function UpdateEvaluateFieldByCriteriaIdAndActivityId(content: IDynamicField, criteriaId: string, activityId: string){

  try {

    const response = await axiosInstance.post(`UpdateEvaluateFieldByCriteriaIdAndActivityId/${criteriaId}/${activityId}`, content);
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

